import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import getWarranty from '../../hooks/getWarranty';

export default function CheckWarranty() {
  const [code, setCode] = useState('');

  const [isLoading, toggleLoading] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const [warranty, setWarranty] = useState(null);

  async function CheckWarranty() {
    setError({ status: false, message: '' });
    toggleLoading(true);
    if (code !== '') {
      let warranty = await getWarranty(code);
      setWarranty(warranty);
      toggleLoading(false);
      if (!warranty)
        setError({ status: true, message: 'Warranty Code Not Found.' });
    } else {
      setError({ status: true, message: 'Required Field is Empty.' });
      toggleLoading(false);
    }
  }

  function handleChange(e) {
    setCode(e.target.value);
  }

  return (
    <Container>
      <h1>Check Your Warranty</h1>
      <Form>
        {error.status && (
          <Error>
            <span>{error.message}</span>
          </Error>
        )}

        <TextField
          id='warranty-code'
          label='Warranty Code'
          required
          onChange={handleChange}
          value={code}
        />
        <button onClick={CheckWarranty} disabled={isLoading}>
          {isLoading ? 'Loading' : 'Check'}
        </button>
      </Form>

      <Accordion
        expanded={warranty !== null}
        disabled={warranty === null}
        style={{ width: 1000, margin: 'auto', marginTop: 30, maxWidth: '100%' }}
      >
        <AccordionSummary
          aria-controls='panel1a-content'
          id='panel1a-header'
          style={{ cursor: 'default' }}
        >
          <Typography>Warranty Status</Typography>
        </AccordionSummary>
        {warranty !== null && (
          <AccordionDetails>
            <Result>
              <p>Customer: {warranty['customer-name']}</p>
              <p>Serial Number: {warranty['serial-number']}</p>
              <p>Purchase Date: {warranty['purchase-date']}</p>
              <p>
                You still have {warranty['daysOfWarranty']} days of warranty.
              </p>
            </Result>
          </AccordionDetails>
        )}
      </Accordion>
    </Container>
  );
}

const Container = styled.div`
  h1 {
    text-align: center;
    padding: 10px;
  }
`;

const Form = styled.div`
  width: 600px;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    width: 100%;
    margin-top: 14px;
  }

  > button {
    margin: 20px auto;
    width: 100px;
    height: 36px;
    cursor: pointer;
    border: 2px solid #1c1c1f;
    font-weight: 500;
    background: none;
    outline: none;

    :hover {
      color: #039fdc;
      border: 2px solid #039fdc;
    }
  }
`;

const Result = styled.div`
  margin: auto;
  width: 600px;
  text-align: center;
  p {
    text-align: center;
  }
`;
const Error = styled.div`
  span {
    text-align: center;
    color: red;
  }
`;
