import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import addWarranty from '../../hooks/addWarranty';
import WarrantyDisplay from './WarrantyDisplay';

export default function RegisterWarranty() {
  const [state, setState] = useState({
    'customer-name': '',
    'customer-email': '',
    'serial-number': '',
    'purchase-date': '',
  });

  const [error, setError] = useState({ status: false, message: '' });

  async function RegisterWarranty() {
    setError({ status: false, message: '' });
    let response = await addWarranty(
      state['customer-name'],
      state['customer-email'],
      state['serial-number'],
      state['purchase-date']
    );

    if (response.status === 200) {
      setState({ ...state, 'warranty-code': response['warranty-code'] });
    } else {
      setError({
        status: true,
        message: response.message,
      });
    }
  }

  function handleDateChange(value) {
    setError({
      status: false,
      message: '',
    });
    var now = moment();
    var purchaseDate = moment(value);
    if (now.diff(purchaseDate, 'days') >= 0) {
      setState({ ...state, 'purchase-date': value });
    } else {
      setError({
        status: true,
        message: 'You cannot set dates on the future.',
      });
      setState({ ...state, 'purchase-date': '' });
    }
  }

  function handleChange(e, name) {
    var { id, value } = e.target;
    id = id ?? name;
    setState({ ...state, [id]: value });
  }

  return (
    <Container>
      {state['warranty-code'] ? (
        <WarrantyDisplay state={state} />
      ) : (
        <>
          <h1>Check Your Warranty</h1>

          <Form>
            {error.status && (
              <Error>
                <span>{error.message}</span>
              </Error>
            )}
            <TextField
              id='customer-name'
              label='Complete Name'
              onChange={handleChange}
              required
              value={state['customer-name']}
            />
            <TextField
              id='customer-email'
              label='Email'
              onChange={handleChange}
              required
              type='email'
              value={state['customer-email']}
            />

            <FormControl required>
              <InputLabel id='serial-code-select'>Serial Code</InputLabel>
              <Select
                labelId='serial-code-select'
                id='serial-number'
                value={state['serial-number']}
                onChange={(e) => {
                  handleChange(e, 'serial-number');
                }}
              >
                <MenuItem value={'IMS-1X'}>ImagineAir 1X - IMS-1X</MenuItem>
                <MenuItem value={'IMS-2X'}>ImagineAir 2X - IMS-2X</MenuItem>
                <MenuItem value={'IMS-2XVS'}>
                  ImagineAir 2X-VS - IMS-2XVS
                </MenuItem>
                <MenuItem value={'IMS-3XTURBO'}>
                  ImagineAir 3X TURBO - IMS-3XTURBO
                </MenuItem>
                <MenuItem value={'IMS-3XVSTURBO'}>
                  ImagineAir 3X-VS TURBO - IMS-3XTURBO
                </MenuItem>
                <MenuItem value={'IMS-PRO'}>ImagineAir PRO - IMS-PRO</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id='purchase-date'
              label='Purchase Date'
              type='date'
              defaultValue={state['purchase-date']}
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={state['purchase-date']}
              onChange={(e) => {
                handleDateChange(e.target.value);
              }}
            />
            <button onClick={RegisterWarranty}>Register</button>
          </Form>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  h1 {
    text-align: center;
    padding: 10px;
  }
`;

const Form = styled.div`
  width: 600px;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    width: 100%;
    margin-top: 14px;
  }

  > button {
    margin: 20px auto;
    width: 100px;
    height: 36px;
    cursor: pointer;
    border: 2px solid #1c1c1f;
    font-weight: 500;
    background: none;
    outline: none;

    :hover {
      color: #039fdc;
      border: 2px solid #039fdc;
    }
  }
`;

const Error = styled.div`
  span {
    text-align: center;
    color: red;
  }
`;
