import React from 'react'
import Link from 'react-router-dom/Link'

import './footer.scss'

export default function Footer() {
    return (
        <div className="footer-nav">
            <div className="container footer-box">
                <div className="footer-content">
                    <div className="footer-about">
                        <ul className='footer-list  navbar-nav'>
                            <span>Get To Know Us</span>
                            <li className="nav-item"><Link className="nav-link" to="/about">About Us</Link></li>
                            {/* <li className="nav-item"><Link className="nav-link" to="/privacy">Privacy Policy</Link></li> */}
                            <li className="nav-item"><Link className="nav-link" to="/returns">Returns</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/contact">Need Help?</Link></li>
                        </ul>
                    </div>
                    <div className="footer-products">
                        <ul className='footer-list navbar-nav'>
                            <span>Products</span>
                            <li className="nav-item"><Link className="nav-link" to="/recirculators">Recirculators</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/certification">Certification</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/info">Technology</Link></li>
                        </ul>
                    </div>

                </div>
                {/* <div className="footer-logo-container">
                    <div className="footer-logo"><span style={{ color: "#fff" }}>Newsletter Component</span></div>
                </div> */}
            </div>
        </div>
    )
}
