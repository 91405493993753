// import { useEffect } from 'react';
import YouTube from 'react-youtube';

export default function Youtube() {
  const opts = {
    height: '768',
    width: '1365',
    maxWidth: '100',

    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: false,
      modestbranding: 1,
      rel: 0,
      enablejsapi: 1,
      // controls: 0,
    },
  };

  return (
    <>
      <YouTube videoId='E7H1usBXqbk' opts={opts} />
    </>
  );
}
