import React from 'react';

export default function Silence() {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 489.423 489.423'
    >
      <g id='g6'>
        <g id='g4'>
          <path
            d='M29.538,142.59C13.25,142.59,0,155.84,0,172.128v145.183c0,16.288,13.25,29.538,29.538,29.538H78.37V142.59H29.538z     M58.678,327.157H29.538c-5.428,0-9.846-4.413-9.846-9.846V172.128c0-5.433,4.418-9.846,9.846-9.846h29.139V327.157z'
            id='path2'
          />
        </g>
      </g>
      <g id='g12'>
        <g id='g10'>
          <path
            d='M282.942,43.099c-9.63-4.885-21.038-3.981-29.519,2.192L103.87,147.224v194.981l149.298,101.76    c5,3.644,10.88,5.49,16.798,5.49c4.418,0,8.861-1.029,12.976-3.125c9.63-4.894,15.611-14.654,15.611-25.452V68.551    C298.553,57.753,292.572,47.994,282.942,43.099z M278.861,420.878c0,4.904-3.389,7.163-4.846,7.904    c-1.471,0.731-5.289,2.135-9.505-0.913l-140.947-96.067V157.628l141.202-96.24c3.971-2.885,7.803-1.481,9.25-0.74    c1.457,0.74,4.846,3,4.846,7.904V420.878z'
            id='path8'
          />
        </g>
      </g>
      <g id='g18'>
        <g id='g16'>
          <polygon
            points='489.423,180.869 475.5,166.946 411.659,230.792 347.817,166.946 333.894,180.869 397.736,244.715     333.894,308.561 347.817,322.484 411.659,258.639 475.5,322.484 489.423,308.561 425.581,244.715   '
            id='polygon14'
          />
        </g>
      </g>
      <g id='g20'></g>
      <g id='g22'></g>
      <g id='g24'></g>
      <g id='g26'></g>
      <g id='g28'></g>
      <g id='g30'></g>
      <g id='g32'></g>
      <g id='g34'></g>
      <g id='g36'></g>
      <g id='g38'></g>
      <g id='g40'></g>
      <g id='g42'></g>
      <g id='g44'></g>
      <g id='g46'></g>
      <g id='g48'></g>
    </svg>
  );
}
