const validateEmail = (email) => {
  if (email.length < 8) return false;

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateName = (name) => {
  const regex = /[<>\\;:$%^*~[\]/,`"{}()|]/g;

  if (name.length < 3) return false;

  if (name.match(regex)) return false;
  else {
    return true;
  }
};
const validatePhone = (phone) => {
  if (phone.length < 10) return false;

  const regex = /[+()\- .,]/g;
  phone = phone.replace(regex, '');

  if (phone.length === 10) phone = '1' + phone;

  var phoneno = /^\d{11}$/;
  if (phone.match(phoneno)) return true;
  else {
    return false;
  }
};
const validateMessage = (message) => {
  const regex = /[<>\\^~[\]/{}()|]/g;

  if (message.length < 1) return false;

  const newLine = /\n/g;

  if (message.match(regex)) return false;
  else {
    message = message.replace(newLine, '<br/>');
    return true;
  }
};

const validations = {
  validateEmail: validateEmail,
  validateName: validateName,
  validatePhone: validatePhone,
  validateMessage: validateMessage,
};

export default validations;
