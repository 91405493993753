import React from 'react';
import styled from 'styled-components';

export default function Silence() {
  return (
    <Container>
      <div
        style={{
          backgroundImage: 'url(/images/room_p.jpg)',
          backgroundPositionY: 'bottom',
        }}
        className='image-bg'
      ></div>
      <div className='silence-information'>
        <h2>
          ImagineAir recirculators use brushless ROHS certified fans that are
          virtually silent in use.
        </h2>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 80vh;
  width: 100%;
  flex-direction: row;
  overflow: hidden;

  div {
    height: 100%;
    width: 50%;
  }

  .image-bg {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    position: relative;
  }
  .silence-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    h2 {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    div {
      position: relative;
      width: 100%;
      height: 80vh;
    }

    .image-bg {
      position: absolute;
      height: 80vh;
    }

    .silence-information {
      background-color: rgba(255, 255, 255, 0.7);
      color: #1c1c1f;
      padding: 0 20px;
      h2 {
        text-align: center;
        font-size: 32px;
      }
    }
  }
  @media (max-width: 480px) {
    div {
      position: relative;
      width: 100%;
      &:nth-child(1) {
        position: absolute;
      }
      &:nth-child(2) {
        background-color: rgba(255, 255, 255, 0.7);
        color: #1c1c1f;
        padding: 0 20px;
        h2 {
          text-align: center;
          font-size: 26px;
        }
      }
    }
  }
`;
