import React from 'react';

// import logo from 'images/logo.svg';
import './Header.scss';

export default function Header(props) {
  const recirculators = props.recirculators;

  return (
    <nav className='navbar sticky-top navbar-expand-lg navbar-light'>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          <img src='/images/logo.svg' alt='logo' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <a className='nav-link' href='/'>
                Home
              </a>
            </li>

            <li className='nav-item dropdown'>
              <a
                className='nav-link dropdown-toggle'
                href='/recirculators'
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Recirculators
              </a>
              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <a className='dropdown-item' href={'/recirculators/'}>
                  All
                </a>
                {recirculators.map((r) => (
                  <a
                    className='dropdown-item'
                    href={'/recirculator/' + r.id}
                    key={r.title}
                  >
                    {r.title}
                  </a>
                ))}
              </div>
            </li>
            <li className='nav-item dropdown'>
              <a
                className='nav-link dropdown-toggle'
                href='/about'
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                About Us
              </a>

              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <a className='dropdown-item' href='/about'>
                  Who We Are
                </a>
                <a className='dropdown-item' href='/returns'>
                  Returns Policy
                </a>
              </div>
            </li>
            <li className='nav-item dropdown'>
              <a
                className='nav-link dropdown-toggle'
                href='/info'
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Technology
              </a>

              <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <a className='dropdown-item' href='/info/decontamination'>
                  Decontamination
                </a>
                <a className='dropdown-item' href='/info/lamps'>
                  Lamps
                </a>
                <a className='dropdown-item' href='/info/silence'>
                  Silence
                </a>
              </div>
            </li>

            <li className='nav-item'>
              <a className='nav-link' href='/certification' id='navbarDropdown'>
                Certification
              </a>
            </li>

            <li className='nav-item'>
              <a className='nav-link' href='/contact'>
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
