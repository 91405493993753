// @flow
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../components/config';

const AboutSimple = ({ imageStyles }) => (
  <Container>
    <div className='about-stripe'></div>
    <div className='about-simple'>
      <div style={{ overflow: 'hidden' }} className='images-panel'>
        <section className='about-simple-panel'>
          <div className='about-simple-panel-bg' style={imageStyles[0]}></div>
          <div className='about-simple-panel-content'>
            <h2>Clean and Effective</h2>
            <p>
              The use of ultraviolet bactericidal radiation has been used
              effectively for years in the sterilization of products, such as,
              titanium implants and contact lenses. ImagineAir recirculators use
              this technology to disinfect the air we breathe in any setting of
              our daily lives. With the use of the latest technology, brushless
              fans and our UV-C lamps emitting ultraviolet radiation at the
              wavelength 253.7nm, we effectively eliminate all microorganisms
              such as, bacteria and viruses from the air we breathe.
            </p>
          </div>
        </section>
        <div className='about-stripe'></div>
        <section className='about-simple-panel'>
          <div className='about-simple-panel-content'>
            <h2>Air quality importance.</h2>
            <p>
              The air quality within the buildings we live and work in, is
              extremely important to our health. Air pollutants and contaminants
              in the air can have a negative effect and can create health
              concerns for some people. Volatile Organic Compounds, like paints
              and aerosols, mould, fungi, bacteria, dust mites, pollen and
              spores are removed from the air we breathe. With the use of
              ImagineAir Systems, we aim to supply you with the cleanest air you
              breathe. Our products give you the piece of mind you need to feel
              safe, and most of all, breathe easy.
            </p>
          </div>
          <div className='about-simple-panel-bg' style={imageStyles[1]}></div>
        </section>
      </div>
    </div>
  </Container>
);

export default AboutSimple;

const Container = styled.div`
  .about-slider {
    width: 100%;
    height: 200vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .about-stripe {
    width: 100%;
    height: 25px;
    background-color: ${colors.primaryColor};
  }

  .images-panel {
    width: 100%;
  }

  .about-pinContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .about-pinContainer .about-simple-panel {
    height: 100vh;
    width: 100%;
    position: absolute;
    text-align: center;
  }

  .about-simple-panel {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;

    .about-simple-panel-bg {
      width: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .about-simple-panel-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      padding: 0 50px;

      h2 {
        text-align: center;
      }

      p {
        text-align: left;
      }
    }
  }

  .panel:nth-child(1) {
    background-position-x: right;
  }

  .slider-content {
    position: absolute;
    width: 50%;
    left: 50%;
    padding: 0 20px;
  }

  .slide {
    height: 100vh;
    font-family: 'Helvetica';
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 48px;
    }

    p {
      font-size: 26px;
      padding: 0 10%;
    }

    hr {
      border-bottom: 2px solid ${colors.generalBlack};
      width: 80%;
      max-width: 500px;
    }
  }

  @media (max-width: 768px) {
    .about-simple-panel {
      position: relative;

      .about-simple-panel-bg {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: -1;
      }

      .about-simple-panel-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 0 30px;

        h2,
        p {
          color: #ffffff;
          width: 100%;
        }
      }
    }
  }
`;
