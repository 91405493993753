import YoutubeVideo from '../../../components/HomeComponents/HomeYoutube/HomeYoutube';
import styled from 'styled-components';

export default function Decontamination() {
  return (
    <Container>
      <div className='full-col-2 full-height'>
        <div
          className='image-bg'
          style={{ backgroundImage: 'url(/images/decontamination.jpg)' }}
        ></div>
        <div className='decontamination-info'>
          <h2>
            All ImagineAir recirculators provide you with the safety of a 99.9%
            decontamination rate of all airborne pathogens in the designated
            square footage.
          </h2>
        </div>
      </div>
      <div className='youtube-container'>
        <YoutubeVideo />
      </div>
    </Container>
  );
}

const Container = styled.div`
  .full-col-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    height: calc(100vh);

    div {
      width: 50%;
    }

    div {
      height: 100%;
      width: 50%;

      &:nth-child(1) {
        background-size: cover;
        background-position-y: top;
        background-position-x: right;
        position: relative;
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        h2 {
          text-align: center;
        }
      }
    }
  }

  .youtube-container {
    width: 100%;
    height: fit-content;
  }

  @media (max-width: 768px) {
    .full-col-2 div {
      position: relative;
      width: 100%;
      &:nth-child(1) {
        position: absolute;
      }
      &:nth-child(2) {
        background-color: rgba(0, 0, 0, 0.7);
        color: #f2f2f2;
        padding: 0 20px;
        h2 {
          text-align: left;
        }
      }
    }
  }
  @media (max-width: 480px) {
  }
`;
