// @flow
import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import styled from 'styled-components';
import { colors } from '../../config';

const HomeSlider = ({ imageStyles, contents }) => (
  <>
    <Slider>
      <div className='home-slider'>
        <div style={{ overflow: 'hidden' }} className='images-panel'>
          <Controller>
            <Scene triggerHook='onLeave' duration='300%' pin>
              <Timeline
                wrapper={
                  <div id='pinContainer' className='home-pinContainer' />
                }
              >
                <section className='panel' style={imageStyles[0]}>
                  <div></div>
                </section>
                <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                  <section className='panel' style={imageStyles[1]}>
                    <div></div>
                  </section>
                </Tween>
                <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                  <section className='panel' style={imageStyles[2]}>
                    <div></div>
                  </section>
                </Tween>
                <Tween from={{ y: '-100%' }} to={{ y: '0%' }}>
                  <section className='panel' style={imageStyles[3]}>
                    <div></div>
                  </section>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>

        <div className='slider-content'>
          {contents.map((content, i) => (
            <div className='slide' key={'slider-content' + i}>
              <h2>{content.title}</h2>
              <hr />
              <p>{content.text}</p>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </Slider>
    <HomeSimple imageStyles={imageStyles} contents={contents} />
  </>
);

const Slider = styled.div`
  display: block;
  @media (max-width: 900px) {
    display: none;
  }

  .home-slider {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .image-panel {
    width: 50%;
  }

  .home-pinContainer {
    height: 100vh;
    width: 50vw;
    overflow: hidden;
  }

  .home-pinContainer .panel {
    height: 100vh;
    width: 50vw;
    position: absolute;
    text-align: center;
  }

  .panel {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .panel:nth-child(1) {
    background-position-x: right;
  }

  .slider-content {
    position: absolute;
    width: 50%;
    left: 50%;
    padding: 0 20px;
  }

  .slide {
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 48px;
    }

    p {
      font-size: 26px;
      padding: 0 10%;
    }

    hr {
      border-bottom: 2px solid ${colors.generalBlack};
      width: 80%;
      max-width: 500px;
    }
  }

  .home-stripe {
    width: 100%;
    height: 25px;
    background-color: ${colors.primaryColor};
  }

  @media (max-width: 1140px) {
    .slide {
      h2 {
        font-size: 48px;
      }

      p {
        text-align: end;
        font-size: 28px;
      }
    }
  }

  @media (max-width: 768px) {
    .parallax-slide-background {
      background-size: cover;
      background-position: right;
    }

    .slide {
      width: 100%;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }

    .slider-content {
      position: absolute;
      width: 100% !important;
      left: 0% !important;
      padding: 0 20px;
    }

    .home-pinContainer {
      width: 100vw;

      .panel {
        width: 100vw;

        div {
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .slide {
      h2 {
        font-size: 42px !important;
        color: ${colors.generalWhite};
      }

      hr {
        border-color: ${colors.generalWhite};
      }

      p {
        font-size: 20px !important;
        text-align: left;
        color: ${colors.generalWhite};
      }
    }
  }

  @media (max-width: 480px) {
    .image-panel {
      width: 100vw;
      height: 100vh;
    }

    .slider-content {
      position: absolute;
      width: 100%;
      left: 0%;
      padding: 0 20px;
    }

    .slide {
      h2 {
        font-size: 32px !important;
        color: ${colors.generalWhite};
      }

      hr {
        border-color: ${colors.generalWhite};
      }

      p {
        font-size: 16px !important;
        text-align: left;
        color: ${colors.generalWhite};
      }
    }
  }
`;

export default HomeSlider;

const HomeSimple = ({ imageStyles, contents }) => {
  return (
    <SimpleContainer>
      <div className='home-simple-content'>
        {contents.length > 0
          ? contents.map((content, i) => {
              return (
                <div key={'simple-slide' + i}>
                  <div className='simple-slide' style={imageStyles[i]}>
                    <div className='simple-home-slide-content'>
                      <h2>{content.title}</h2>
                      <hr />
                      <p>{content.text}</p>
                      <hr />
                    </div>
                  </div>
                  <div
                    key={content.title + i}
                    className='home-simple-stripe'
                  ></div>
                </div>
              );
            })
          : null}
      </div>
    </SimpleContainer>
  );
};

const SimpleContainer = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
.simple-image-panel {
  width: 100%;
}

.slider-content {
  position: absolute;
  width: 50%;
  left: 50%;
  padding: 0 20px;
}

.simple-slide {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;

  h2 {
    font-size: 48px;
    color: #f2f2f2;
  }

  p {
    font-size: 26px;
    padding: 0 10%;
    color: #f2f2f2;
  }

  hr {
    border-bottom: 2px solid ${colors.generalWhite};
    width: 80%;
    max-width: 500px;
  }
}

.home-simple-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.home-simple-stripe {
  width: 100%;
  height: 25px;
  background-color: ${colors.primaryColor};
}

.simple-home-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 30px;

  hr {
    border-color: ${colors.generalWhite};
  }
}

@media (max-width: 900px) {
  .simple-slide {
    h2 {
      font-size: 48px;
      color: ${colors.generalWhite};
    }

    p {
      text-align: center;
      font-size: 28px;
      color: ${colors.generalWhite};
    }
  }
}

@media (max-width: 768px) {
  .simple-slide {
    h2 {
      font-size: 42px;
      color: ${colors.generalWhite};
    }

    hr {
      border-color: ${colors.generalWhite};
    }

    p {
      font-size: 20px;
      text-align: left;
      color: ${colors.generalWhite};
    }
  }
}

@media (max-width: 480px) {
  .image-panel {
    width: 100vw;
    height: 100vh;
  }

  .slider-content {
    position: absolute;
    width: 100%;
    left: 0%;
    padding: 0 20px;
  }

  .simple-slide {
    h2 {
      font-size: 28px;
      color: ${colors.generalWhite};
    }

    hr {
      border-color: ${colors.generalWhite};
    }

    p {
      font-size: 16px;
      text-align: center;
      color: ${colors.generalWhite};
    }
  }
`;
