const recirculators = [
  {
    id: "EQ1042",
    acmedentId: 51987,
    regularPrice: 749.95,
    promoPrice: null,
    title: "ImagineAir 1X",
    specifications: {
      area: "320 sqft.",
      decontamination: 99.9,
      categoryPremises: ["A", "B", "C", "E"],
      SourceUvRadiation: "Ozone free bacterial lamp",
      numberOfLamps: 3,
      lampPower: "15W",
      lampLife: "9000 Hours",
      radiationWavelength: "253.7 nm",
      uvPower: "14.4W",
      programmableTimer: true,
      airFlowCapacity: "2710 ft³/min",
      lampIndicators: 3,
      electricalConnection: "3ft Power Cord",
      type: "wall-mounted",
      standBase: false,
      consumedPower: "55W",
      inputPower: "110V",
      material: "Metal",
      weight: "3.7 kg",
      dimensions: "126X127X614 mm",
      wifi: "optional",
    },
    description: "",
    images: [
      "/images/products/ImagineAir 1X.png",
      "/images/products/ImagineAir 1X 3D-model.png",
      "/images/certificate.png",
    ],
  },
  {
    id: "EQ1043",
    acmedentId: 52003,
    regularPrice: 995.0,
    promoPrice: null,
    title: "ImagineAir 2X",
    specifications: {
      area: "645 sqft.",
      decontamination: 99.9,
      categoryPremises: ["A", "B", "C", "E"],
      SourceUvRadiation: "Ozone free bacterial lamp",
      numberOfLamps: 3,
      lampPower: "30W",
      lampLife: "9000 Hours",
      radiationWavelength: "253.7 nm",
      uvPower: "36W",
      programmableTimer: true,
      airFlowCapacity: "6710 ft³/min",
      lampIndicators: 3,
      electricalConnection: "3ft Power Cord",
      type: "wall-mounted",
      standBase: false,
      consumedPower: "98W",
      inputPower: "110V",
      material: "Metal",
      weight: "6.9 kg",
      dimensions: "126X127X1214 mm",
      wifi: "optional",
    },
    description: "",
    images: [
      "/images/products/ImagineAir 2X.png",
      "/images/products/StandWS.png",
      "/images/certificate.png",
    ],
  },
  //   {
  //     id: "IA000002XVS",
  //     acmedentId: 52033,
  //     regularPrice: 1099.95,
  //     promoPrice: null,
  //     title: "ImagineAir 2X-VS",
  //     specifications: {
  //       area: "645 sqft.",
  //       decontamination: 99.9,
  //       categoryPremises: ["A", "B", "C", "E"],
  //       SourceUvRadiation: "Ozone free bacterial lamp",
  //       numberOfLamps: 3,
  //       lampPower: "30W",
  //       lampLife: "9000 Hours",
  //       radiationWavelength: "253.7 nm",
  //       uvPower: "36W",
  //       programmableTimer: true,
  //       airFlowCapacity: "6710 ft³/min",
  //       lampIndicators: 3,
  //       electricalConnection: "3ft Power Cord",
  //       type: "stand",
  //       standBase: true,
  //       consumedPower: "98W",
  //       inputPower: "110V",
  //       material: "Metal",
  //       weight: "6.9 kg",
  //       dimensions: "126X127X1214 mm",
  //       wifi: "optional",
  //     },
  //     description: "",
  //     images: [
  //       "/images/products/ImagineAir 4X-VS.png",
  //       "/images/certificate.png",
  //     ],
  //   },
  {
    id: "EQ1044",
    acmedentId: 52005,
    regularPrice: 1395.0,
    promoPrice: null,
    title: "ImagineAir 3X Turbo",
    specifications: {
      area: "1184 sqft.",
      decontamination: 99.9,
      categoryPremises: ["A", "B", "C", "E"],
      SourceUvRadiation: "Ozone free bacterial lamp",
      numberOfLamps: 3,
      lampPower: "55W",
      lampLife: "9000 Hours",
      radiationWavelength: "253.7 nm",
      uvPower: "54W",
      programmableTimer: true,
      airFlowCapacity: "13420 ft³/min",
      lampIndicators: 3,
      electricalConnection: "3ft Power Cord",
      type: "wall-mounted",
      standBase: false,
      consumedPower: "220W",
      inputPower: "110V",
      material: "Metal",
      weight: "7.0 kg",
      dimensions: "126X127X1214 mm",
      wifi: "optional",
    },
    description: "",
    images: [
      "/images/products/ImagineAir 3X.png",
      "/images/products/StandWS.png",
      "/images/certificate.png",
    ],
  },
  //   {
  //     id: "IA000003XVSTURBO",
  //     acmedentId: 52006,
  //     regularPrice: 1399.95,
  //     promoPrice: null,
  //     title: "ImagineAir 3X-VS Turbo",
  //     specifications: {
  //       area: "1184 sqft.",
  //       decontamination: 99.9,
  //       categoryPremises: ["A", "C", "E"],
  //       SourceUvRadiation: "Ozone free bacterial lamp",
  //       numberOfLamps: 3,
  //       lampPower: "55W",
  //       lampLife: "9000 Hours",
  //       radiationWavelength: "253.7 nm",
  //       uvPower: "54W",
  //       programmableTimer: true,
  //       airFlowCapacity: "13420 ft³/min",
  //       lampIndicators: 3,
  //       electricalConnection: "3ft Power Cord",
  //       type: "wall-mounted",
  //       standBase: true,
  //       consumedPower: "220W",
  //       inputPower: "110V",
  //       material: "Metal",
  //       weight: "7.0 kg",
  //       dimensions: "126X127X1214 mm",
  //       wifi: "optional",
  //     },
  //     description: "",
  //     images: [
  //       "/images/products/ImagineAir 4X-VS.png",
  //       "/images/certificate.png",
  //     ],
  //   },
  {
    id: "EQ1046",
    acmedentId: 52008,
    regularPrice: 2995.95,
    promoPrice: null,
    title: "ImagineAir PRO",
    specifications: {
      area: "4305 sqft.",
      decontamination: 99.9,
      categoryPremises: ["A", "B", "C", "E"],
      SourceUvRadiation: "Ozone free bacterial lamp",
      numberOfLamps: 9,
      lampPower: "55W",
      lampLife: "9000 Hours",
      radiationWavelength: "253.7 nm",
      uvPower: "162W",
      programmableTimer: true,
      airFlowCapacity: "26486 ft³/min",
      lampIndicators: 3,
      electricalConnection: "3ft Power Cord",
      type: "wall-mounted",
      standBase: false,
      consumedPower: "550W",
      inputPower: "110V",
      material: "Metal",
      weight: "14.5 kg",
      dimensions: "169X453X1300 mm",
      wifi: "included",
    },
    description: "",
    images: ["/images/products/ImagineAir 4X.png", "/images/certificate.png"],
  },
  {
    id: "EQ1045",
    acmedentId: 52058,
    regularPrice: 99.95,
    promoPrice: null,
    title: "ImagineAir Vertical Stand",
    specifications: {
      material: "Metal",
      weight: "1.5 kg",
      dimensions: "300X300X400 mm",
      wifi: "",
    },
    description: "",
    images: ["/images/products/Stand.png"],
  },
];

export default recirculators;
