import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaVolumeUp, FaVolumeMute, FaUndoAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { colors } from '../../config';

export default function HomeIntroVideo() {
  const videoDom = useRef(null);
  const [looping, setLooping] = useState(true);
  const [muted, setMuted] = useState(true);
  const [ended, setEnded] = useState(false);

  const playWithAudio = () => {
    if (looping) {
      setLooping(false);
      setMuted(false);
      videoDom.current.src = '/videos/3d_ImagineAir_video.webm';
      videoDom.current.muted = false;
      videoDom.current.currentTime = 0;
      videoDom.current.volume = 1;
      videoDom.current.onended = function () {
        setEnded(true);
      };
    }
  };

  const playAgain = () => {
    setEnded(false);
    setTimeout(() => {
      videoDom.current.onended = function () {
        setEnded(true);
      };
      videoDom.current.currentTime = 0;
      videoDom.current.play();
    }, 200);
  };
  const toggleMuteVideo = () => {
    setMuted(!muted);
    if (muted) videoDom.current.volume = 1;
    else videoDom.current.volume = 0;
  };

  useEffect(() => {}, [looping]);
  return (
    <Container className='home-intro-video-display'>
      <div className='home-intro-video'>
        <figure className='startframe'></figure>
        {looping ? (
          <video
            id='banner-video'
            autoPlay
            playsInline
            muted={muted}
            loop={true}
            ref={videoDom}
          >
            <source
              src={'/videos/3d_ImagineAir_video.webm'}
              type='video/webm'
            />
            <source src={'/videos/3d_ImagineAir_video.mp4'} type='video/mp4' />
            <img src='/images/background.jpg' alt='Air sanitizers' />
          </video>
        ) : (
          <video
            id='banner-video'
            autoPlay
            playsInline
            muted={muted}
            loop={false}
            ref={videoDom}
            onClick={playWithAudio}
          >
            <source
              src={'/videos/3d_ImagineAir_video.webm'}
              type='video/webm'
            />
            <source src={'/videos/3d_ImagineAir_video.mp4'} type='video/mp4' />
            <img src='/images/background.jpg' alt='Air sanitizers' />
          </video>
        )}

        <figure className='endframe'></figure>
      </div>
      <div className='video-controls' onClick={playWithAudio}>
        <div className='video-shop-btn'>
          <Link to='/recirculators'>SHOP</Link>
        </div>
        {!looping ? (
          <div className='video-mute-btn'>
            <span onClick={toggleMuteVideo}>
              {!muted ? <FaVolumeUp /> : <FaVolumeMute />}
            </span>
          </div>
        ) : null}
        {ended ? (
          <div className='video-replay-btn'>
            <span onClick={playAgain}>
              <FaUndoAlt />
            </span>
          </div>
        ) : null}
      </div>
    </Container>
  );
}

const Container = styled.div`
  .startframe,
  .endframe {
    margin: 0;
  }

  position: relative;
  max-width: 100%;
  height: fit-content;
  min-height: 55vw;
  background-color: #f2f2f2;
  margin-top: -6px;

  .home-intro-video {
    position: relative;
    video,
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .video-controls {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 5px);
    width: 100%;

    .video-shop-btn {
      position: absolute;
      bottom: 7%;
      left: 15%;
      transform: translate(-50%, -50%);
      border: 5px solid #fefefe;
      padding: 5px 14px;
      transition: 0.1s;

      &:hover {
        border: 14px double #fefefe;
      }
      a {
        color: #fefefe;
        text-decoration: none;
        font-weight: bold;
        font-size: 4vw;
      }
    }
    .video-replay-btn {
      position: absolute;
      bottom: 20%;
      transform: translateX(-50%);
      left: 50%;
    }

    .video-mute-btn {
      position: absolute;
      bottom: 20%;
      right: 10%;
    }

    .video-mute-btn,
    .video-replay-btn {
      span {
        cursor: pointer;
        font-size: 36px;
        color: ${colors.primaryColor};
        &:hover {
          color: #0d7aa5;
        }
      }
    }

    @media (max-width: 900px) {
      .video-replay-btn {
        bottom: 10%;
      }

      .video-mute-btn {
        bottom: 10%;
      }

      .video-mute-btn,
      .video-replay-btn {
        span {
          cursor: pointer;
          font-size: 24px;
        }
      }
    }
  }
`;
