import React, { useState } from 'react';
import styled from 'styled-components';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CheckWarranty from './CheckWarranty';
import RegisterWarranty from './RegisterWarranty';

export default function Warranty() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Navbar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Check Warranty' />
          <Tab label='Register Warranty' />
        </Tabs>
      </Navbar>
      <TabPanel value={value} index={0}>
        <CheckWarranty />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RegisterWarranty />
      </TabPanel>
    </Container>
  );
}

const Container = styled.div`
  h1 {
    text-align: center;
    padding: 10px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const Navbar = styled(AppBar)`
  justify-content: center;
  align-items: center;
`;
