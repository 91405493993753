import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { colors } from '../../components/config';

import Decontamination from './Decontamination/Decontamination';
import Lamps from './Lamps/Lamps';
import Silence from './Silence/Silence';

export default function Informations(props) {
  const Content = () => {
    switch (props.tab.toLowerCase()) {
      case 'decontamination':
        return <Decontamination />;
      case 'lamps':
        return <Lamps />;
      case 'silence':
        return <Silence />;
      default:
        return <Redirect to='/info/lamps' />;
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          Technology | {props.tab.charAt(0).toUpperCase() + props.tab.slice(1)}{' '}
          | ImagineAir Systems
        </title>
        <meta
          name='description'
          content='ImagineAir is focused on bringing our products to healthcare,
          hotel/restaurant retail, and transportation industries. A complete
          solution for everyone.'
        />
        <link
          rel='canonical'
          href={'https://imagineairsystems.com/info/' + props.tab}
        />
      </Helmet>
      <div>
        <Header tab={props.tab} />
        <Content />
      </div>
    </>
  );
}

const Header = (props) => {
  return (
    <Navigator className='dropdown info-nav-dropdown'>
      <button
        className='btn btn-secondary dropdown-toggle'
        type='button'
        id='dropdownMenu2'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {props.tab}
      </button>
      <div
        className='dropdown-menu info-nav-dropdown-menu'
        aria-labelledby='dropdownMenu2'
      >
        <Link className='nav-link' to='/info/lamps'>
          Lamps
        </Link>
        <Link className='nav-link' to='/info/decontamination'>
          Decontamination
        </Link>
        <Link className='nav-link' to='/info/silence'>
          Silence
        </Link>
      </div>
    </Navigator>
  );
};

const Navigator = styled.div`
  width: 100%;
  button {
    width: 100%;
    border-radius: 0;
    background-color: ${colors.primaryColor};
    text-transform: capitalize;
  }

  .info-nav-dropdown-menu {
    width: 95%;
    margin: 0 2.5%;
    left: 0;
    transform: translateY(38px) !important;
    padding: 0;
    text-align: center;
  }
`;
