import axios from 'axios';
import moment from 'moment';

export default async function getWarranty(code) {
  const config = {
    url: process.env.REACT_APP_API_BASE_URL + 'get-warranty',
    data: { code: code },
  };

  return await axios
    .post(config.url, config.data)
    .then((response) => {
      return {
        ...response.data,
        daysOfWarranty: calculateWarranty(response.data['purchase-date']),
      };
    })
    .catch((err) => {
      return null;
    });
}

function calculateWarranty(p_date) {
  var now = moment();
  var purchaseDate = moment(p_date);
  var result = 366 - now.diff(purchaseDate, 'days');

  return result >= 0 ? result : 0;
}
