import styled from 'styled-components';

import Youtube from '../../Youtube/Youtube';

export default function HomeYoutube() {
  return (
    <Container>
      <div className='youtube-video-panel'>
        <Youtube />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  justify-content: center;
  align-items: center;

  .youtube-video-panel {
    width: 100%;
    background-color: black;

    &,
    & div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 0;
      height: fit-content;
      align-items: center;
      overflow: hidden;
    }

    div {
      width: 100%;
      iframe {
        max-width: 100vw;
        height: 56vw;
        max-height: 100vh;
        width: 100vw;
        height: 56vw;
        margin: 0;
        padding: 0;
      }
    }
  }

  @media (max-width: 1080px) {
    .youtube-video-panel {
      iframe {
        max-width: 100%;
        max-height: 50vh;
      }
    }
  }
  @media (max-height: 400px) {
    .youtube-video-panel {
      iframe {
        max-width: 100% !important;
        max-height: 100vh !important;
        margin: 0;
        padding: 0;
      }
    }
  }
`;
