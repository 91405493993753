import { useState } from 'react';

import { FaPlus, FaMinus } from 'react-icons/fa';
import Modal from 'react-modal';

import './Redirect.scss';

export default function Redirect({ id, price }) {
  const [quantity, setQuantity] = useState(1);
  const acmeId = id;
  const acmedentUrl =
    'https://www.acmedent.com/cart/?add-to-cart=' +
    acmeId +
    '&quantity=' +
    quantity;

  const subQty = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };
  const addQty = () => {
    setQuantity(quantity + 1);
  };

  const handleChange = (value) => {
    if (value >= 1) setQuantity(value);
    else setQuantity(1);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={'store'}>
      <div className={'quantity-display'}>
        <div onClick={subQty}>
          <FaMinus />
        </div>
        <div>
          <input
            type='number'
            value={quantity}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
        </div>
        <div onClick={addQty}>
          <FaPlus />
        </div>
      </div>
      {/* <a
        target='_blank'
        rel='noreferrer'
        href={acmedentUrl}
        className={'add-to-cart'}
      >
        Buy
      </a> */}
      <div className={'add-to-cart'} onClick={handleShow}>
        Buy
      </div>
      <Modal
        isOpen={show}
        onAfterOpen={handleShow}
        onRequestClose={handleClose}
        className='modal-vendor'
        contentLabel='Vendors Modal'
      >
        <h2>Authorized Vendors</h2>
        <hr />
        <div>
          <div className='vendor-info'>
            <div>
              <img src='/Acmedent.png' alt='Acmedent' />
            </div>
            <div>
              <span>Acmedent Corporation, Toronto CA</span>
            </div>
          </div>
          <a
            href={acmedentUrl}
            target='_blank'
            rel='noreferrer'
            className='vendor-buy'
            onClick={() => {
              handleClose();
              setTimeout(() => {
                setQuantity(1);
              }, 200);
            }}
          >
            Select
          </a>
        </div>
        <button onClick={handleClose}>Cancel</button>
      </Modal>
    </div>
  );
}
