import React from 'react';

import styled from 'styled-components';

import HomeSlider from '../../components/HomeComponents/HomeSlider/HomeSlider';
import ProductsGrid from '../../components/Recirculators/ProductsGrid';
import HomeIconLinks from '../../components/HomeComponents/HomeIconLinks/HomeIconLinks';
import HomeIntroVideo from '../../components/HomeComponents/HomeIntroVideo/HomeIntroVideo';
import Home3DVideo from '../../components/HomeComponents/Home3DVideo/Home3DVideo';

export default function Home(props) {
  const imageStyles = [
    {
      backgroundImage: "url('images/places/office_p.jpg')",
      backgroundPosition: 'left',
    },
    { backgroundImage: 'url("/images/places/mall.jpeg")' },
    { backgroundImage: 'url("/images/places/variety.jpg")' },
    {
      backgroundImage: "url('images/places/certificate-store.jpg')",
      backgroundPosition: 'center',
    },
  ];

  const contents = [
    {
      title: 'The All-in-One Solution',
      text: 'ImagineAir has combined the use of UV-C germicidal bulbs with an air filtration device, to deliver a complete solution for sterilized air.',
    },
    {
      title: 'Environmentally Friendly and Energy Efficient',
      text: 'Our UV-C technology uses minimal energy to operate at maximum efficiency, resulting in an environmentally and economically friendly product.',
    },
    {
      title: 'Variety',
      text: 'With the ImagineAir series of recirculators, we have all areas covered from small to big. Starting with our 1X model covering 320 sqft. to our Pro model covering over 8600 sqft.',
    },
    {
      title: 'Guaranteed Clean Air',
      text: 'Each ImagineAir system includes a Certificate of Clean Air that you can proudly display in your business to give you, the staff and your clientele the piece of mind.',
    },
  ];

  return (
    <Container>
      <HomeIntroVideo />
      {/* <HomeVideo /> */}
      <div className='home-simple-stripe'></div>
      <div>
        <HomeSlider imageStyles={imageStyles} contents={contents} />
      </div>
      <div className='home-simple-stripe'></div>
      <Home3DVideo />
      <HomeIconLinks />
      <ProductsGrid recirculators={props.recirculators} />
    </Container>
  );
}

const Container = styled.div`
  .home-banner {
    width: 100%;
    height: 42.63vw;
    // background-image: url('images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10vw;

    div {
      padding-top: 5vw;
      width: 30%;
      height: calc(100% + 5vw);
      max-height: calc(100% + 5vw);
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  .divider {
    width: 100%;
    margin: 50px auto;

    hr {
      border-bottom: 1px solid #c1c1c1;
    }
  }

  .home-simple-stripe {
    width: 100%;
    height: 25px;
    background-color: #039fdc;
  }
`;
