import React from 'react';

export default function TimeManagement() {
  return (
    <svg height='512' viewBox='0 0 64 64' width='512' version='1.1' id='svg26'>
      <path d='m31 56h2v2h-2z' id='path2' />
      <path
        d='m48.678 48.678h2v2h-2z'
        transform='matrix(.707 -.707 .707 .707 -20.577 49.678)'
        id='path4'
      />
      <path d='m56 31h2v2h-2z' id='path6' />
      <path
        d='m61 25h-7.09a22.547 22.547 0 0 0 -1.465-3.542l5.011-5.012a1 1 0 0 0 0-1.414l-4.243-4.242a30 30 0 1 0 -42.426 42.423l4.242 4.243a1 1 0 0 0 1.414 0l5.012-5.011a22.559 22.559 0 0 0 3.545 1.464v7.091a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-7.09a22.559 22.559 0 0 0 3.542-1.464l5.012 5.011a1 1 0 0 0 1.414 0l8.485-8.485a1 1 0 0 0 0-1.414l-5.011-5.012a22.559 22.559 0 0 0 1.467-3.546h7.091a1 1 0 0 0 1-1v-12a1 1 0 0 0 -1-1zm-48.8-12.8a27.989 27.989 0 0 1 38.874-.69l-1.412 1.412a26 26 0 0 0 -36.74 36.74l-1.412 1.412a27.989 27.989 0 0 1 .69-38.874zm28.285 11.315a12 12 0 0 1 -16.97 16.97 1 1 0 0 0 -1.414 0l-7.764 7.764a24 24 0 0 1 33.912-33.912l-7.764 7.763a1 1 0 0 0 0 1.415zm19.515 13.485h-6.836a1 1 0 0 0 -.964.732 20.49 20.49 0 0 1 -1.861 4.5 1 1 0 0 0 .166 1.2l4.829 4.83-7.07 7.07-4.83-4.829a1 1 0 0 0 -1.2-.166 20.49 20.49 0 0 1 -4.5 1.861 1 1 0 0 0 -.732.964v6.838h-10.002v-6.836a1 1 0 0 0 -.732-.964 20.49 20.49 0 0 1 -4.5-1.861 1 1 0 0 0 -1.2.166l-4.83 4.829-2.828-2.828 9.925-9.924a14 14 0 0 0 19.747-19.749l9.924-9.925 2.828 2.828-4.829 4.83a1 1 0 0 0 -.166 1.2 20.473 20.473 0 0 1 1.861 4.5 1 1 0 0 0 .964.732h6.836z'
        id='path8'
      />
      <path
        d='m33 28.142v-11.142a1 1 0 0 0 -2 0v11.142a4 4 0 0 0 -2.858 2.858h-7.142a1 1 0 0 0 0 2h7.142a3.992 3.992 0 1 0 4.858-4.858zm-1 5.858a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z'
        id='path10'
      />
      <path
        d='m32 13a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1z'
        id='path12'
      />
      <path
        d='m42 15.68a1 1 0 0 1 -.865-1.5l1-1.733a1 1 0 0 1 1.732 1l-1 1.733a1 1 0 0 1 -.867.5z'
        id='path14'
      />
      <path
        d='m22 15.68a1 1 0 0 1 -.867-.5l-1-1.733a1 1 0 0 1 1.732-1l1 1.733a1 1 0 0 1 -.865 1.5z'
        id='path16'
      />
      <path
        d='m14.679 23a.992.992 0 0 1 -.5-.134l-1.733-1a1 1 0 0 1 1-1.732l1.733 1a1 1 0 0 1 -.5 1.866z'
        id='path18'
      />
      <path d='m12 33h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2z' id='path20' />
      <path
        d='m12.948 44a1 1 0 0 1 -.5-1.866l1.733-1a1 1 0 1 1 1 1.732l-1.733 1a.992.992 0 0 1 -.5.134z'
        id='path22'
      />
      <path
        d='m32 42a9.914 9.914 0 0 1 -7.068-2.934 1 1 0 1 1 1.416-1.412 7.995 7.995 0 1 0 11.306-11.306 1 1 0 1 1 1.412-1.416 10 10 0 0 1 -7.066 17.068z'
        id='path24'
      />
    </svg>
  );
}
