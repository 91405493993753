import AboutSlider from './AboutSlider';
import AboutSimple from './AboutSimple';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Fade from 'react-reveal/Fade';

export default function About() {
  const imageStyles = [
    {
      backgroundImage: "url('images/places/Big Office_p.jpg')",
      backgroundPosition: 'left',
    },
    {
      backgroundImage: "url('images/places/school_p.jpg')",
      backgroundPositionY: 'top',
      backgroundPositionX: 'right',
    },
  ];

  return (
    <Container>
      <Helmet>
        <meta charSet='utf-8' />
        <title>About Us | ImagineAir Systems</title>
        <description>
          At ImagineAir, we strive to provide a complete solution for the clean
          air we breathe. Through the sourcing of various technologies, we have
          concluded that UV-C recirculators provide the safest and most
          effective air sterilization products. We, at ImagineAir, are focused
          on bringing our products to healthcare, hotel/restaurant retail, and
          transportation industries. A complete solution for everyone. With
          ImagineAir systems, you can breathe easy.
        </description>
        <link rel='canonical' href='http://imagineairsystems.com/about' />
      </Helmet>
      <div className='container about-text'>
        <div className='about-title'>
          <Fade top>
            <h2>About Us</h2>
          </Fade>
        </div>
        <div className='about-content'>
          <Fade>
            <p>
              At ImagineAir, we strive to provide a complete solution for the
              clean air we breathe. Through the sourcing of various
              technologies, we have concluded that UV-C recirculators provide
              the safest and most effective air sterilization products. We, at
              ImagineAir, are focused on bringing our products to healthcare,
              hotel/restaurant retail, and transportation industries. A complete
              solution for everyone. With ImagineAir systems, you can breathe
              easy.
            </p>
          </Fade>
        </div>
      </div>
      <div className='slider-about'>
        <AboutSlider imageStyles={imageStyles} />
      </div>
      <div className='simple-about'>
        <AboutSimple imageStyles={imageStyles} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  .about-text {
    margin-top: 20px;
    margin-bottom: 100px;

    h2 {
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-bottom: 7px;
      border-bottom: 1px solid #c2c2c2;
      font-size: 50px;
    }

    p {
      font-size: 26px;
      text-align: center;
    }
  }

  .simple-about {
    display: none;
  }
  .slider-about {
    display: block;
  }

  @media (max-width: 900px) {
    .about-text {
      h2 {
        font-size: 32px;
      }

      p {
        font-size: 20px;
      }
    }

    .simple-about {
      display: block;
    }
    .slider-about {
      display: none;
    }
  }
`;
