import React from 'react';
import Helmet from 'react-helmet';

import './Recirculator.scss';
import Wifi from '../HomeComponents/HomeIconLinks/Icons/Wifi';
import Redirect from "../Shop/Acmedent/Redirect";

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Recirculator(props) {
  const item = props.item;

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{item.title} | ImagineAir Systems</title>
        <description>
          At ImagineAir, we strive to provide a complete solution for the clean
          air we breathe. Through the sourcing of various technologies, we have
          concluded that UV-C recirculators provide the safest and most
          effective air sterilization products. We, at ImagineAir, are focused
          on bringing our products to healthcare, hotel/restaurant retail, and
          transportation industries. A complete solution for everyone. With
          ImagineAir systems, you can breathe easy.
        </description>
        <link
          rel='canonical'
          href={'http://imagineairsystems.com/recirculator/' + item.id}
        />
      </Helmet>
      <div className='single-content-wrapper'>
        <div className='single-image-wrapper'>
          <div
            className='item-wifi-icon'
            style={{
              display:
                item.specifications.wifi === 'included' ? 'block' : 'none',
            }}
          >
            <Wifi />
          </div>
          <Carousel
            className='product-slider'
            labels={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={item.images.length > 1}
            swipeable={item.images.length > 1}
            infiniteLoop={item.images.length > 1}
            emulateTouch
            swipeScrollTolerance={0}
          >
            {item.images.map((i) => (
              <div className='single-item-slide'>
                <img src={i} alt='Recirculator' />
              </div>
            ))}
          </Carousel>
        </div>
        <div className='single-information-wrapper'>
          <h2>{item.title}</h2>
          {item.specifications?.area && <>
            <h3>
              <sup>
                <small>Up to</small>
              </sup>
              {" " + item.specifications.area}
            </h3>
          </>}
          <hr />
          <div className='item-price'>
            <h2>{'$' + item.regularPrice.toFixed(2)}</h2>
            <small>SKU: {item.id}</small>
          </div>
          <hr />
          <p>
            The use of ultraviolet bactericidal radiation has been used
            effectively for years in the sterilization of products, such as,
            titanium implants and contact lenses. ImagineAir recirculators use
            this technology to disinfect the air we breathe in any setting of
            our daily lives. With the use of the latest technology, brushless
            fans and our UV lamps emitting ultraviolet radiation at the
            wavelength 253.7nm, we effectively eliminate all microorganisms such
            as, bacteria and viruses from the air we breathe.
          </p>
          <hr />
          <h4>Features</h4>
          <ul>
            <li> Uses ozone free germicidal lamps (253.7nm) </li>
            <li> Brushless fans</li>
            <li>
              Eliminates the spread of viruses and bacteria in the area of use
            </li>
            <li> Safe in the presence of people, pets, and plants </li>
            <li> 9000 hour lamp life </li>
            <li> Hour counter on every unit </li>
            <li> Wifi capabilities (not all models)</li>
            <li> 1 year warranty </li>
            <li> Significant increase to the air flow in the room</li>
            <li>
              Substantial increase in ACH (air exchange per hour) in a room
            </li>
          </ul>
          {item.acmedentId ? (
            <div>
              <Redirect id={item.acmedentId} price={item.regularPrice} />
            </div>
          ) : null}
        </div>
      </div>
      <div className='single-content-wrapper' style={{ margin: '30px auto' }}>
        <div className='single-product-specifications'>
          <h3>Specifications</h3>
          <table>
            {item.specifications?.area ? (
              <tr>
                <td>Area of the room</td>
                <td>
                  <small>Up to </small>
                  {item.specifications.area}
                </td>
              </tr>
            ) : null}
            {item.specifications?.decontamination ? (
              <tr>
                <td>Degree of Decontamination</td>
                <td>{item.specifications.decontamination}%</td>
              </tr>
            ) : null}
            {item.specifications?.airFlowCapacity ? (
              <tr>
                <td>Air flow capacity*</td>
                <td>{item.specifications.airFlowCapacity}</td>
              </tr>
            ) : null}
            {item.specifications?.wifi ? (
              <tr>
                <td>Wi-Fi</td>
                <td>{item.specifications.wifi}</td>
              </tr>
            ) : null}
            {item.specifications?.categoryPremises ? (
              <tr>
                <td>Categories premises*</td>
                <td>{item.specifications.categoryPremises}</td>
              </tr>
            ) : null}
            {item.specifications?.SourceUvRadiation ? (
              <tr>
                <td>Source UV-C radiation</td>
                <td>{item.specifications.SourceUvRadiation}</td>
              </tr>
            ) : null}
            {item.specifications?.lampPower ? (
              <tr>
                <td>Number of germicidal lamps</td>
                <td>
                  {item.specifications.numberOfLamps}{' '}
                  {item.specifications.lampPower
                    ? ' of ' + item.specifications.lampPower
                    : ''}
                </td>
              </tr>
            ) : null}
            {item.specifications?.lampLife ? (
              <tr>
                <td>Effective lamp life</td>
                <td>{item.specifications.lampLife}</td>
              </tr>
            ) : null}
            {item.specifications?.radiationWavelength ? (
              <tr>
                <td>Radiation wavelength</td>
                <td>{item.specifications.radiationWavelength}</td>
              </tr>
            ) : null}
            {item.specifications?.uvPower ? (
              <tr>
                <td>Power UV-C radiation</td>
                <td>{item.specifications.uvPower}</td>
              </tr>
            ) : null}
            {item.specifications?.programmableTimer ? (
              <tr>
                <td>Programmable timer</td>
                <td>{item.specifications.programmableTimer ? 'Yes' : 'No'}</td>
              </tr>
            ) : null}
            {item.specifications?.lampIndicators ? (
              <tr>
                <td>Lamp operability indicators</td>
                <td>{item.specifications.lampIndicators}</td>
              </tr>
            ) : null}
            {item.specifications?.electricalConnection ? (
              <tr>
                <td>Electrical connection</td>
                <td>{item.specifications.electricalConnection}</td>
              </tr>
            ) : null}
            {item.specifications?.type ? (
              <tr>
                <td>Option exploitation</td>
                <td>{item.specifications.type}</td>
              </tr>
            ) : null}
            {item.specifications?.standBase ? (
              <tr>
                <td>Stand base</td>
                <td>{item.specifications.standBase ? 'Yes' : 'No'}</td>
              </tr>
            ) : null}
            {item.specifications?.consumedPower ? (
              <tr>
                <td>Consumed power</td>
                <td>{item.specifications.consumedPower}</td>
              </tr>
            ) : null}
            {item.specifications?.inputPower ? (
              <tr>
                <td>AC Input Power</td>
                <td>{item.specifications.inputPower}</td>
              </tr>
            ) : null}
            {item.specifications?.material ? (
              <tr>
                <td>Body material</td>
                <td>{item.specifications.material}</td>
              </tr>
            ) : null}
            {item.specifications?.weight ? (
              <tr>
                <td>Weight</td>
                <td>{item.specifications.weight}</td>
              </tr>
            ) : null}
            {item.specifications?.dimensions ? (
              <tr>
                <td>Dimentions</td>
                <td>{item.specifications.dimensions}</td>
              </tr>
            ) : null}
          </table>
        </div>
        <div className='single-usage-areas'>
          <h3>Areas of Usage</h3>

          <ol>
            <li>
              <b>Health Care - </b>Hospitals, Physician Offices, Medical
              Laboratories, Dental Offices, Clinical Specialties (vision care,
              physio clinics, rehab centres)
            </li>
            <li>
              <b>Hotel, Restaurants, Conference, Airports, and Schools</b>
            </li>
            <li>
              <b>Retail - </b>Shopping Centers/Grocery Stores/Health and Beauty
              Centers/Fitness Facilities
            </li>
            <li>
              <b>Transportation - </b>Aviation/Ground Transportation (buses,
              taxis, cars)/Trains/Subways/Elevators
            </li>
            <li>
              <b>Other - </b>Homes/Condo’s/Warehouse Facilities/Factories/ Sport
              complex’s/Banks
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
