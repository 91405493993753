import React from 'react';
import Helmet from 'react-helmet';

import './Recirculators.scss';

import ProductsGrid from './ProductsGrid';

export default function Recirculators(props) {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Recirculators | ImagineAir Systems</title>
        <description>
          At ImagineAir, we strive to provide a complete solution for the clean
          air we breathe. Through the sourcing of various technologies, we have
          concluded that UV-C recirculators provide the safest and most
          effective air sterilization products. We, at ImagineAir, are focused
          on bringing our products to healthcare, hotel/restaurant retail, and
          transportation industries. A complete solution for everyone. With
          ImagineAir systems, you can breathe easy.
        </description>
        <link
          rel='canonical'
          href='http://imagineairsystems.com/recirculators/'
        />
      </Helmet>
      <ProductsGrid recirculators={props.recirculators} />
    </>
  );
}
