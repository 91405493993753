import React from 'react'
import Helmet from 'react-helmet'

export default function PageNotFound() {

    const styles = {
        box: {
            width: '100%',
            height: 'calc(100vh - 260px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        message: {
            textAlign: 'center'
        }
    }

    return (
        <div style={styles.box}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Page Not Found | ImagineAir Systems</title>
                <description>At ImagineAir, we strive to provide a complete solution for the clean air we breathe. Through the sourcing of various technologies, we have concluded that UV-C recirculators provide the safest and most effective air sterilization products. We, at ImagineAir, are focused on bringing our products to healthcare, hotel/restaurant retail, and transportation industries. A complete solution for everyone. With ImagineAir systems, you can breathe easy.</description>
                <link rel="canonical" href="http://imagineairsystems.com/page-not-found" />
            </Helmet>
            <h4 style={styles.message}>Ops! The page you were looking for doesn't exist...</h4>
        </div>
    )
}
