import React from 'react';
import styled from 'styled-components';

export default function Lamps() {
  return (
    <Container>
      <div className='col-2'>
        <div>
          <h3>
            OSRAM PURITEC® HNS® germicidal lamps for a wide range of
            applications
          </h3>
          <p>
            OSRAM PURITEC® HNS® are low-pressure lamps that emit mercury line
            radiation mainly in the short-wave ultraviolet range. They are the
            highly promising ecofriendly alternatives to chemical purification
            processes. OSRAM offers many different lamps for effective
            purification in a wide range of applications. OSRAM PURITEC® HNS®
            are available in different sizes and outputs to suit different
            requirements.
          </p>
        </div>
        <div>
          <h3>OSRAM PURITEC® HNS® UV-C lamps for purification</h3>
          <p>
            The UV-C radiation generated by high-energy UV-C lamps moves at a
            wavelength range between 200 nanometers (nm) and 280 nanometers.
            This gives the radiation a cell-destroying effect that is ideal for
            highly effective purifi cation of water, air and surfaces and
            eliminates the need for chemical products.
          </p>
        </div>
        <div>
          <h3>Working principle of UV-C lamps</h3>
          <p>
            UV-C radiation has a photolytic effect on DNA, microorganisms such
            as bacteria, moulds, yeasts and virus cannot replicate. The
            purification effect is obtained with wavelengths below 320 nm, with
            maximum effectiveness at 260 nm. OSRAM HNS germicidal lamps emit
            light at 254 nm, about 85% of maximum effectiveness, and are
            ozone-free.
          </p>
        </div>
        <div>
          <h3>Ozone-free lamps</h3>
          <p>
            The main lines in the spectrum are at 254 nm and 185 nm. Ultraviolet
            light, which has a wavelength of less than 240 nm, produces ozone.
            High-energy radiation below 240 nm can pass through quartz. If ozone
            is not required then special glass or appropriate types of quartz
            are used. OSRAM PURITEC® HNS® are ozone-free lamps. They are
            transparent for the radiation that kills micro-organisms but do not
            let ozonegenerating radiation through.
          </p>
        </div>
      </div>
      <hr />
      <div className='col-2'>
        <div>
          <h3>Product benefits</h3>
          <ul>
            <li>
              Effective, environmentally friendly purification without chemicals
            </li>
            <li>Long lifetime due to specific coating</li>
            <li>Wide range of form factors</li>
            <li>Perfect ballast and lamp fit</li>
            <li>Very low mercury content</li>
            <li>100% RoHS-conform</li>
            <li>Lead-free</li>
            <li>Ozone-free</li>
            <li>Made in Europe</li>
          </ul>
        </div>
        <div>
          <h3>Product features</h3>
          <ul>
            <li>Single UV-C peak at wavelength of 254 nm</li>
            <li>High efficiency (30% UV-C output)</li>
            <li>Non-ozone generating</li>
            <li>Soft glass tube</li>
            <li>With UV-C transparent long-life coating</li>
            <li>Without fluorescent coating</li>
            <li>Low pressure Hg discharge lamps</li>
          </ul>
        </div>
      </div>
      <hr />
      <div className='info-h2'>
        <h2>Areas of Application</h2>
      </div>
      <div className='col-2'>
        <div>
          <h3>Water purification</h3>
          <p>
            Water must often be rid of pathogenic microorganisms to become safe
            for drinking. Ultraviolet radiation is employed to change the DNA
            structure of the microorganisms, either killing the bacteria
            immediately or rendering them unable to breed. Because UV-C
            purification is a physical disinfection method, without any harmful
            chemicals, it doesn’t cause secondary pollution. This means there is
            no malodorous smell in the water or by-products.
          </p>
          <p>
            <b>For water purification and cleansing in:</b>
          </p>
          <ul>
            <li>Private households</li>
            <li>Water dispensers</li>
            <li>Community water works</li>
            <li>Mobile stations (camping, outdoor activities)</li>
            <li>Swimming pools</li>
            <li>Ultra-pure water systems</li>
            <li>Ponds and aquariums</li>
            <li>Fish farms</li>
            <li>Food processing factories</li>
            <li>Sewage systems</li>
          </ul>
        </div>
        <div className='images-row-2'>
          <img
            src='/images/info/lamps/lamps-2.png'
            alt='Water Purifications'
            style={{ objectPosition: 'bottom' }}
          />
          <img src='/images/info/lamps/lamps-3.png' alt='Water Purifications' />
        </div>
      </div>
      <div className='col-2 reverse-row'>
        <div>
          <h3>Air purification</h3>
          <p>
            Ultraviolet (UV-C) purification is a very effective method to clean
            the air of biological pollutants such as bacteria, viruses and
            fungal spores. UV-C germicidal lamps can be installed in ventilation
            ducts to clean the air passing through them. UV-C air purification
            is more economical and efficient than other air filtration and
            cleaning methods.
          </p>
          <p>
            <b>For air purification in:</b>
          </p>
          <ul>
            <li>Hospitals</li>
            <li>Doctors’ practices</li>
            <li>Clean rooms</li>
            <li>Offices with or without air-conditioning systems</li>
            <li>Cars</li>
            <li>Storage rooms</li>
            <li>Food processing</li>
            <li>Rooms with frequent public access </li>
            <li>Animal stalls</li>
          </ul>
        </div>
        <div className='images-row-2'>
          <img src='/images/info/lamps/lamps-4.png' alt='Air Purifications' />
          <img src='/images/info/lamps/lamps-5.png' alt='Air Purifications' />
        </div>
      </div>
      <div className='col-2'>
        <div>
          <h3>Surface cleaning</h3>
          <p>
            For packaging pharmaceuticals and food, in aseptic zones in
            hospitals and for surface cleaning of equipment and instruments
            objects are exposed directly to UV-C radiation.
          </p>
          <p>
            <b>For surface purification in:</b>
          </p>
          <ul>
            <li>Hospitals and other aseptic zones</li>
            <li>Health care</li>
            <li>Food and pharmaceutical industry</li>
          </ul>
        </div>
        <div className='images-row-2'>
          <img src='/images/info/lamps/lamps-6.png' alt='Surface cleaning' />
          <img src='/images/info/lamps/lamps-7.png' alt='Surface cleaning' />
        </div>
      </div>
      <hr />

      <div className='col-2'>
        <div>
          <h3>OSRAM ballasts for PURITEC® HNS® lamps</h3>
          <p>
            OSRAM as a system provider with know-how in lamp and ECG development
            offers VDE-accredited single and twin lamp ballasts for a wide range
            of different lamps.
          </p>
          <p>
            <b>Product benefits:</b>
          </p>
          <ul>
            <li>High performance</li>
            <li>Long lifetime</li>
            <li>
              Easy, quick and economic upgrade to energy-efficient ECG operation
            </li>
          </ul>
        </div>
        <div className='images-col-1'>
          <img
            src='/images/info/lamps/lamps-1.png'
            alt='UV-C Lamp'
            style={{ objectPosition: 'bottom' }}
          />
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  hr {
    width: 60%;
    border-color: #4b4b4d;
  }

  .col-2 {
    width: 1024px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;

    div {
      width: 50%;
      padding: 20px;
      p {
        text-align: left;
      }

      ul {
        padding: 0;
        padding-left: 10px;
        list-style-type: '-  ';
      }
    }
  }

  .col-1 {
    width: 1024px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;

    div {
      width: 100%;
      padding: 10px;
      p {
        text-align: left;
      }

      ul {
        padding: 0;
        padding-left: 10px;
        list-style-type: '-  ';
      }
    }
  }

  .full-height {
    height: calc(100vh - 120px);
  }

  .full-col-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;

    div {
      width: 50%;
    }
  }

  @media (max-width: 1024px) {
    .col-2,
    .col-2 {
      max-width: 95%;
      div {
        width: 100%;
        margin: auto;
        padding: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    .col-2 {
      div {
        h2 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
          text-align: left;
        }
      }
    }

    .full-height {
      height: calc(100vh);
    }
  }

  .full-col-2 {
    div {
      width: 100%;
    }
  }

  .info-h2 {
    margin: auto;
    width: fit-content;
  }

  .reverse-row {
    flex-direction: row-reverse !important;
  }

  .images-col-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    img {
      width: 50%;
      object-fit: cover;
      height: 300px;
    }
  }

  .images-row-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }

  .images-col-1 {
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }
`;
