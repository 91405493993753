import './App.scss';

import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import Helmet from 'react-helmet';

import ScrollToTop from './ScrollToTop';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

//Routes
import About from './pages/About/About';
import Certification from './pages/Certification/Certification';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home';
import Informations from './pages/Informations/Informations';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Returns from './pages/Returns/Returns';
import Recirculators from './components/Recirculators/Recirculators';
import Recirculator from './components/Recirculators/Recirculator';
import Warranty from './pages/Warranty/Warranty';

import recirculators from './recirculators';

function App() {
  return (
    <div className='App'>
      <Helmet>
        <meta
          name='description'
          content='ImagineAir has combined the use of UV-C germicidal bulbs with an air filteration device, to deliver a complete solution for sterilized air.'
        />
      </Helmet>
      <Header recirculators={recirculators} />
      <ScrollToTop>
        <div className='app-body'>
          <Switch>
            <Route
              exact
              path='/'
              render={() => <Home recirculators={recirculators} />}
            />
            <Route exact path='/about' render={() => <About />} />
            <Route exact path='/returns' render={() => <Returns />} />
            <Route
              exact
              path='/certification'
              render={() => <Certification />}
            />
            <Route
              exact
              path='/recirculators'
              render={() => <Recirculators recirculators={recirculators} />}
            />
            <Route
              exact
              path='/recirculator/:id'
              render={(props) => {
                let recirculator = _.filter(recirculators, (a) => {
                  return (
                    a.id.toLowerCase() === props.match.params.id.toLowerCase()
                  );
                });
                if (recirculator.length)
                  return <Recirculator item={recirculator[0]} />;
                else return <Redirect to='/recirculators' />;
              }}
            />
            <Route exact path='/info'>
              <Redirect to='/info/decontamination' />
            </Route>
            <Route
              exact
              path='/info/:tab'
              render={(props) => <Informations tab={props.match.params.tab} />}
            />
            <Route exact path='/contact' render={() => <Contact />} />
            <Route exact path='/warranty' render={() => <Warranty />} />
            <Route exact path='**' render={() => <PageNotFound />} />
          </Switch>
        </div>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
