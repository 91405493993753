import React from 'react';

export default function Led() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Outline'
      height='512'
      viewBox='0 0 64 64'
      width='512'
      version='1.1'
    >
      <defs id='defs23' />

      <path
        d='m59.40179 11.01227 2.30521-2.30527-1.414-1.414-2.29294 2.29288-3.58581-3.58606 2.29275-2.29282-1.414-1.414-2.30582 2.30575a3.0699 3.0699 0 0 0 -4.10827.10828l-44.17237 44.17188a2.99744 2.99744 0 0 0 -.10854 4.10909l-2.305 2.305 1.414 1.414 2.29273-2.29272 3.58593 3.58593-2.29266 2.29279 1.414 1.414 2.30463-2.30463a2.97707 2.97707 0 0 0 4.10943-.10943l44.1724-44.17185a2.99687 2.99687 0 0 0 .10833-4.10882zm-15.40161 1.402 7.5857 7.58569-32.58612 32.58573-7.5857-7.58569zm-30.29364 45.46464a1.021 1.021 0 0 1 -1.41357 0l-6.17139-6.17191a1.00185 1.00185 0 0 1 -.00049-1.414l3.87891-3.87894 7.58569 7.5857zm44.17237-44.17191-4.87891 4.87894-7.58569-7.5857 4.87915-4.87915a1.021 1.021 0 0 1 1.41357 0l6.17139 6.17191a1.00185 1.00185 0 0 1 .00049 1.414z'
        id='path2'
        style={{ fillOpacity: 1 }}
      />
      <path d='m31 2h2v5h-2z' id='path4' style={{ fillOpacity: 1 }} />
      <path
        d='m11.555 10.055h2v5h-2z'
        transform='matrix(.707 -.707 .707 .707 -5.2 12.556)'
        id='path6'
        style={{ fillOpacity: 1 }}
      />
      <path d='m2 31h5v2h-5z' id='path8' style={{ fillOpacity: 1 }} />
      <path d='m31 57h2v5h-2z' id='path10' style={{ fillOpacity: 1 }} />
      <path
        d='m50.446 48.945h2v5h-2z'
        transform='matrix(.707 -.707 .707 .707 -21.309 51.451)'
        id='path12'
        style={{ fillOpacity: 1 }}
      />
      <path d='m57 31h5v2h-5z' id='path14' style={{ fillOpacity: 1 }} />
      <path
        d='m49.877 29.88379-1.98633.23242a16.26465 16.26465 0 0 1 .10933 1.88379 16.01833 16.01833 0 0 1 -16 16 16.25994 16.25994 0 0 1 -1.88379-.10938l-.2334 1.98633a18.01515 18.01515 0 0 0 20.11719-17.87695 18.31146 18.31146 0 0 0 -.123-2.11621z'
        id='path16'
        style={{ fillOpacity: 1 }}
      />
      <path
        d='m14.12305 34.11621 1.98633-.23242a16.26465 16.26465 0 0 1 -.10938-1.88379 16.01833 16.01833 0 0 1 16-16 16.25148 16.25148 0 0 1 1.8833.10938l.2334-1.98633a18.01469 18.01469 0 0 0 -20.1167 17.87695 18.31146 18.31146 0 0 0 .12305 2.11621z'
        id='path18'
        style={{ fillOpacity: 1 }}
      />
    </svg>
  );
}
