import React from 'react';
import styled from 'styled-components';

export default function WarrantyDisplay(props) {
  const state = {
    'customer-name': props.state['customer-name'] ?? '1',
    'serial-number': props.state['serial-number'] ?? '2',
    'purchase-date': props.state['purchase-date'] ?? '3',
    'warranty-code': props.state['warranty-code'] ?? '4',
  };

  return (
    <Container>
      <div>
        <h3>Thank You!</h3>
        <h2>Warranty Registered.</h2>
        <div>
          <p>Customer: {state['customer-name']}</p>
          <p>Serial Number: {state['serial-number']}</p>
          <p>Purchase Date: {state['purchase-date']}</p>
          <p>Warranty Code: {state['warranty-code']}</p>
        </div>
        <small>
          Please save this information for your records as it pertains to your
          warranty.
        </small>
        <br />
        <Print
          onClick={() => {
            window.print();
          }}
        >
          Print
        </Print>
      </div>
    </Container>
  );
}

const Container = styled.div`
  & > div {
    width: 600px;
    margin: auto;
    text-align: center;
    padding: 50px;

    > div {
      margin-top: 20px;
      border: 1px solid #c0c0c0;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > p {
        border-bottom: 1px solid #c0c0c0;
        width: 90%;
      }
    }
  }
`;

const Print = styled.small`
  cursor: pointer;

  :hover {
    color: #007bff;
  }
`;
