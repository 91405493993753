import styled from 'styled-components';

export default function Home3DVideo() {
  return (
    <Container>
      <div className='home-3d-video'>
        <video
          id='banner-video'
          controls
          playsInline
          poster='/videos/ImagineAir_clip_poster.jpg'
        >
          <source
            src={'/videos/imagineair-intro-full.webm'}
            type='video/webm'
          />
          <source src={'/videos/imagineair-intro-full.mp4'} type='video/mp4' />
          <img src='/images/background.jpg' alt='Air sanitizers' />
        </video>
        <figure className='endframe'></figure>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: fit-content;
  max-height: calc(100vh - 80px);
  background-color: #f2f2f2;
  margin-top: -6px;

  .home-3d-video {
    position: relative;
    height: 100%;
    video,
    img {
      width: 100%;
      background-color: #000;
      object-fit: contain;
      max-height: calc(100vh - 80px);
    }
  }

  @media (max-width: 900px) {
  }
`;
