import './Item.scss';

import Wifi from '../HomeComponents/HomeIconLinks/Icons/Wifi';

export default function Item(props) {
  const item = props.recirculator;

  return (
    <div className='item-display'>
      {item.specifications.wifi === 'included' ? (
        <div className='wifi-icon'>
          <Wifi />
        </div>
      ) : null}
      {item.miniPicture ? (
        <div className='wifi-icon'>
          <MiniPicture path={item.miniPicture} />
        </div>
      ) : null}

      <div className='item-sqm'>
        <h3>
          {item.specifications?.area ? (
            <>
              <sup style={{ fontSize: 10 }}>Up to </sup>
              {item.specifications.area}
            </>
          ) : null}
        </h3>
      </div>
      <div className='item-img'>
        <img src={item.images[0]} alt={item.title} />
      </div>
      <div className='item-title'>
        <h3>{item.title}</h3>
      </div>
      <div className='item-button'>
        <span>DETAILS</span>
      </div>
    </div>
  );
}

const MiniPicture = ({ path }) => {
  return (
    <div className='mini-picture'>
      <img src={path} alt='' />
    </div>
  );
};
