import axios from 'axios';

export default async function addWarranty(name, email, serial, p_date) {
  if (name === '' || serial === '' || p_date === '') {
    return { status: 301, message: 'Required field empty.' };
  } else {
    const config = {
      url: process.env.REACT_APP_API_BASE_URL + 'add-warranty',
      data: {
        'customer-name': name,
        'customer-email': email,
        'serial-number': serial,
        'purchase-date': p_date,
      },
    };

    return await axios
      .post(config.url, config.data)
      .then(async (response) => {
        return { ...response.data, status: 200 };
      })
      .catch((err) => {
        return {
          status: 301,
          message:
            'Something went wrong, please try again or contact our sales team.',
        };
      });
  }
}
