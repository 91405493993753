import Helmet from 'react-helmet';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

export default function Returns() {
  return (
    <Container className='container returns-content'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Returns | ImagineAir Systems</title>
        <description>
          At ImagineAir, we strive to provide a complete solution for the clean
          air we breathe. Through the sourcing of various technologies, we have
          concluded that UV-C recirculators provide the safest and most
          effective air sterilization products. We, at ImagineAir, are focused
          on bringing our products to healthcare, hotel/restaurant retail, and
          transportation industries. A complete solution for everyone. With
          ImagineAir systems, you can breathe easy.
        </description>
        <link rel='canonical' href='http://imagineairsystems.com/returns' />
      </Helmet>
      <div className='content-block' id='terms'>
        <Fade cascade>
          <h2 className='content-h2'>Terms of Sale</h2>
        </Fade>
        <div>
          <Fade top>
            <h3 className='content-title'>Opening an Account</h3>
          </Fade>
          <Fade right>
            <p className='content-paragraph'>
              Licensed practitioners may be billed on an open account. All
              accounts are due within 30 days from our invoice date. Equipment
              orders are due on delivery. An interest charge of 2.0% per month
              will be levied on past due accounts. Prices are in Canadian
              dollars and do not include P.S.T., H.S.T. (harmonized sales tax),
              G.S.T. or freight. Equipment and special order products require a
              non-refundable deposit of 25%. All equipment and special orders
              cannot be canceled and are non-returnable. Payment plans are
              available only if prior payment arrangements have been made. Ask
              about our convenient leasing plans that can be tailored
              specifically to your equipment purchases. A $60.00 service charge
              applies to all non-negotiated (NSF) cheques. All first time orders
              require a valid credit card on file.
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Prices</h3>
          </Fade>
          <Fade left>
            <p className='content-paragraph'>
              All prices are published in Canadian Dollars. We are not
              responsible for typographical errors. We do our best to maintain
              published prices. However, all prices are subject to change at any
              time without notice. Price increases may be passed on in the event
              of adverse currency exchange fluctuations or manufacturer price
              increases.
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Taxes</h3>
          </Fade>
          <Fade right>
            <p className='content-paragraph'>
              Prices quoted in all Acmedent publication including web site do
              not include GST (Goods and Services Tax) PST (Provincial Sales
              Tax) or HST (Harmonized Sales Tax).
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Payment Methods</h3>
          </Fade>
          <Fade left>
            <p className='content-paragraph'>
              Visa, MasterCard, American Express, C.O.D and prepaid are
              accepted.
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Equipment Orders</h3>
          </Fade>
          <Fade right>
            <p className='content-paragraph'>
              A 30% deposit is required on all equipment orders over $500.00 The
              balance of payment is due on delivery of said equipment. Acmedent
              Corporation retains ownership of any equipment that is purchased
              from Acmedent Corporation and had not been paid for in full.
              Equipment orders can not be cancelled and are not refundable.
              Architectural and design services are available for a moderate
              fee. We honor all manufacturer warranties and provide a 90 day
              labor warranty.
            </p>
          </Fade>
        </div>
      </div>
      <div className='content-text' id='returns'>
        <Fade top>
          <h2 className='content-h2'>Returns & Shipping</h2>
        </Fade>
        <div>
          <Fade top>
            <h3 className='content-title'>Shipping</h3>
          </Fade>
          <Fade left>
            <p className='content-paragraph'>
              Merchandise orders are normally processed the day of receipt and
              shipped by courier the same day. Delivery is generally next day or
              within two days in Ontario and Quebec. Freight is free on all
              merchandise orders over $300 (before taxes), otherwise a minimum
              freight charge of $12 will be added to the original invoice.
              Freight charges are applicable for warranty repair items.
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Claims</h3>
          </Fade>
          <Fade right>
            <p className='content-paragraph'>
              Please check your order immediately upon receipt. If there is any
              damage in transit or any shortage to the order, please advise us
              within two business days of receipt of the goods.
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Dangerous Goods</h3>
          </Fade>
          <Fade left>
            <p className='content-paragraph'>
              Some products are deemed to be dangerous by the Ministry of
              Transport. Additional chargers may apply on certain products.
            </p>
          </Fade>
        </div>
        <div>
          <Fade top>
            <h3 className='content-title'>Returns</h3>
          </Fade>
          <Fade right>
            <p className='content-paragraph'>
              Please follow the guidelines in order to return an item for
              credit:
            </p>
          </Fade>
          <ol className='content-list'>
            <Fade left>
              <li>Return the item within 30 days of purchase.</li>
            </Fade>
            <Fade right>
              <li>
                Package the item carefully and return it in the original
                unmarked, unopened package along with a copy of the original
                invoice and return authorization number written on the outside
                of the shipping container.
              </li>
            </Fade>
            <Fade left>
              <li>
                If any item is defective, please return the remaining portion of
                the product and enclose a note explaining why the item is
                defective.
              </li>
            </Fade>
            <Fade right>
              <li>
                Special order items are not returnable for credit for any
                reason.
              </li>
            </Fade>
            <Fade left>
              <li>
                Handpieces and equipment returned for credit are subject to a
                minimum 20% restocking fee (this does not apply to returns for
                repair under warranty) For your protection opened handpieces and
                equipment may not be returned for credit, but will be repaired
                or replaced in accordance with manufacturers warranties.
              </li>
            </Fade>
          </ol>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .content-h2 {
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
    margin: 20px 0;
  }

  .content-title {
  }

  .content-paragraph {
    font-size: 22px;
    text-align: justify;
  }

  .content-list {
    li {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    .content-h2 {
      font-size: 30px;
    }

    .content-paragraph {
      font-size: 18px;
    }

    .content-title {
      font-size: 24px;
    }

    .content-list {
      li {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 480px) {
    .content-h2 {
      font-size: 26px;
    }

    .content-paragraph {
      font-size: 14px;
    }

    .content-title {
      font-size: 20px;
    }

    .content-list {
      li {
        font-size: 14px;
      }
    }
  }
`;
