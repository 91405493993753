import Helmet from 'react-helmet';
import { colors } from '../../components/config';
import styled from 'styled-components';

import EmailForm from './EmailForm';

import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaEnvelopeSquare,
} from 'react-icons/fa';

export default function Contact() {
  const socialMedia = [
    {
      name: 'Facebook',
      link: '#',
      icon: <FaFacebookSquare />,
    },
    {
      name: 'Instagram',
      link: '#',
      icon: <FaInstagramSquare />,
    },
    {
      name: 'Twitter',
      link: '#',
      icon: <FaTwitterSquare />,
    },
    {
      name: 'Email',
      link: 'mailto:info@imagineairsystems.com',
      icon: <FaEnvelopeSquare />,
    },
  ];

  return (
    <Container className='container'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Contact Us | ImagineAir Systems</title>
        <description>
          At ImagineAir, we strive to provide a complete solution for the clean
          air we breathe. Through the sourcing of various technologies, we have
          concluded that UV-C recirculators provide the safest and most
          effective air sterilization products. We, at ImagineAir, are focused
          on bringing our products to healthcare, hotel/restaurant retail, and
          transportation industries. A complete solution for everyone. With
          ImagineAir systems, you can breathe easy.
        </description>
        <link rel='canonical' href='http://imagineairsystems.com/contact' />
      </Helmet>
      <div>
        <h1>Contact Us</h1>
        <hr />
        <div className='contact-info'>
          <h4>Call Us</h4>
          <a href='tel:14168952717'>
            <span>+1 (416) 895-2717</span>
          </a>
        </div>
        <EmailForm />
        <hr />
        <div className='social-media-links'>
          {socialMedia.map((sm) => (
            <div key={sm.icon}>
              <a href={sm.link}>{sm.icon}</a>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 50px;
  min-height: calc(100vh - 70px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    text-align: center;
  }

  hr {
    width: 120px;
  }

  .social-media-links {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    justify-content: center;
    div {
      a {
        width: 60px;
        color: ${colors.generalBlack};
        height: 60px;
        font-size: 50px;
        transition: 0.1s;
        cursor: pointer;
        &:hover {
          color: ${colors.primaryColor};
        }
      }
    }
  }

  .contact-info {
    margin: auto;
    width: fit-content;

    text-align: center;
  }
`;
