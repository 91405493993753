import { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import validations from '../../utils/validations';
import { colors } from '../../components/config';

export default function EmailForm() {
  const [state, setState] = useState({
    email: '',
    name: '',
    phone: '',
    company: '',
    subject: '',
    message: '',
  });

  const [error, setError] = useState({
    email: false,
    name: false,
    phone: false,
    subject: false,
    message: false,
  });

  const [title, setTitle] = useState('Send a Message');
  const [subtitle, setSubtitle] = useState('');
  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState();

  const [valid, setValid] = useState(false);

  const handleText = (target) => {
    setState({ ...state, [target.id]: target.value });
    validateData({ ...state, [target.id]: target.value });
  };

  const validateData = (data) => {
    let val = true;
    val = validations.validateEmail(data.email);
    val = val ? validations.validateName(data.name) : val;
    val = val ? validations.validatePhone(data.phone) : val;
    val = val ? validations.validateMessage(data.subject) : val;
    val = val ? validations.validateMessage(data.message) : val;

    setError({
      email: !validations.validateEmail(data.email),
      name: !validations.validateName(data.name),
      phone: !validations.validatePhone(data.phone),
      subject: !validations.validateMessage(data.subject),
      message: !validations.validateMessage(data.message),
    });

    setValid(val);

    return val;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setValid(state);

    if (validateData(state)) {
      //send axios request here
      axios
        .post('https://imagine-air-api.herokuapp.com/email/send', state)
        .then(function (response) {
          // console.log(response);
          setSuccess(response.data.validationStatus || false);
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(state);
    }

    setTitle('Thank you for your message! ');
    setSubtitle('Our staff will respond to you shortly.');
    setSent(true);

    return true;
  };

  return (
    <Container className='email-form'>
      <h4>{title}</h4>
      {subtitle !== '' ? <h6 style={{ color: '#4c4c4f' }}>{subtitle} </h6> : ''}
      {success === true ? (
        <h2 style={{ color: '#63c763' }}>
          Your Message was Successful Delivered!!!!!
        </h2>
      ) : success === false ? (
        <h3 style={{ color: '#fd6b6b' }}>
          Sorry, something went wrong. Please try again or give us a call.
        </h3>
      ) : (
        ''
      )}
      <form
        action=''
        onSubmit={onSubmit}
        style={{ display: sent ? 'none' : 'flex' }}
      >
        <label htmlFor='email'>
          Email: <span style={{ color: 'red' }}>*</span> <br />
          <input
            type='text'
            id='email'
            name='email-form'
            value={state.email}
            style={error.email ? { border: '1px red solid' } : {}}
            onChange={(e) => {
              handleText(e.target);
            }}
            placeholder='john.doe@domain.com'
          />
        </label>
        <label htmlFor='name'>
          Name: <span style={{ color: 'red' }}>*</span> <br />
          <input
            type='text'
            id='name'
            name='name-form'
            value={state.name}
            style={error.name ? { border: '1px red solid' } : {}}
            onChange={(e) => {
              handleText(e.target);
            }}
            placeholder='John Doe'
          />
        </label>
        <label htmlFor='phone'>
          Phone: <span style={{ color: 'red' }}>*</span>
          <br />
          <input
            type='text'
            id='phone'
            name='phone-form'
            style={error.phone ? { border: '1px red solid' } : {}}
            value={state.phone}
            onChange={(e) => {
              handleText(e.target);
            }}
            placeholder='XXX XXX XXXX'
          />
        </label>
        <label htmlFor='company'>
          Company Name: <br />
          <input
            type='text'
            id='company'
            name='company-form'
            value={state.company}
            onChange={(e) => {
              handleText(e.target);
            }}
            placeholder='Optional'
          />
        </label>
        <label htmlFor='subject'>
          Subject: <span style={{ color: 'red' }}>*</span> <br />
          <input
            type='text'
            id='subject'
            name='subject-form'
            style={error.subject ? { border: '1px red solid' } : {}}
            value={state.subject}
            onChange={(e) => {
              handleText(e.target);
            }}
          />
        </label>
        <label htmlFor='message'>
          Message: <span style={{ color: 'red' }}>*</span> <br />
          <textarea
            type='text'
            id='message'
            name='message-form'
            value={state.message}
            style={error.message ? { border: '1px red solid' } : {}}
            rows={4}
            onChange={(e) => {
              handleText(e.target);
            }}
          />
        </label>
        <input type='submit' value='Send' id='submit' disabled={!valid} />
      </form>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  margin-top: 20px;
  text-align: center;
  form {
    width: 300px;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;

    label {
      input,
      textarea {
        width: 100%;
        resize: none;
      }
    }
  }

  .invisible {
    display: none;
  }

  #submit {
    outline: none;
    border-radius: 0;
    border: 0;
    background-color: #1c1c1f;
    color: #fefefe;
    cursor: pointer;
    width: 200px;
    margin: 0 auto;
    height: 35px;
    transition: 0.01s;

    &:disabled {
      cursor: default;
      &:hover {
        background-color: #dddddd;
        color: #5c5c5f;
      }
      background-color: #dddddd;
      border: 1px solid#bcbcbf;
      color: #5c5c5f;
    }

    &:hover {
      background-color: ${colors.primaryColor};
    }
  }
`;
