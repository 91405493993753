import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../config';

// import './HomeIconLinks.scss';
import Led from './Icons/Led';
import NoVirus from './Icons/NoVirus';
import Shield from './Icons/Shield';
import TimeManagement from './Icons/TimeManagement';
import Silence from './Icons/Silence';

export default function HomeIconLinks() {
  return (
    <Container>
      <div className='container home-icons-nav' id='home-icons'>
        <Link to='/info/decontamination'>
          <div>
            <NoVirus />
            <h3>Decontamination 99.9%</h3>
          </div>
        </Link>
        <Link to='/info/lamps'>
          <div>
            <Led />
            <h3>3-12 Lamps</h3>
          </div>
        </Link>
        <a href='/#home-icons'>
          <div>
            <Shield />
            <h3>Absolutely Safe</h3>
          </div>
        </a>
        <Link to='/info/silence'>
          <div>
            <Silence />
            <h3>Silent</h3>
          </div>
        </Link>
        <a href='/#home-icons'>
          <div>
            <TimeManagement />
            <h3>Maximum Efficiency</h3>
          </div>
        </a>
      </div>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${colors.primaryColor};

  .home-icons-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    row-gap: 0;
    justify-content: space-evenly;

    a {
      width: 20%;
      height: 25%;
      text-decoration: none;

      div {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        &:hover {
          background-color: ${colors.secondaryColor};

          svg {
            // fill: ${colors.generalBlack};
            fill: ${colors.generalWhite};

            g {
              // fill: ${colors.generalBlack};
              fill: ${colors.generalWhite};
            }
          }

          h3 {
            // color: ${colors.generalBlack};
            color: ${colors.generalWhite};
          }
        }

        img,
        svg {
          fill: ${colors.generalWhite};
          width: 30%;
          height: 50%;
          object-fit: contain;
          margin-bottom: 20px;

          g {
            fill: ${colors.generalWhite};
          }
        }

        h3 {
          text-align: center;
          font-size: 16px;
          color: ${colors.generalWhite};
        }
      }
    }
  }

  @media (max-width: 768px) {
    .home-icons-nav {
      padding: 0;
      height: 120px;
      a {
        height: 100% !important;
        div {
          height: 100% !important;
          min-height: 120px !important;
          img,
          svg {
            width: 60% !important;
            height: 50%;
            margin-bottom: 0px !important;

            g {
              fill: ${colors.generalWhite};
            }
          }

          h3 {
            display: none;
          }
        }
      }
    }
  }
`;
