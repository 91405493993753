import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { colors } from '../config';

import Item from './Item';

// import './HomeGrid.scss';

export default function ProductsGrid(props) {
  const products = props.recirculators;

  return (
    <Container>
      <Header to='/recirculators' className='home-header-flex'>
        <div className='imaginair-series'>
          <div className='products-slider'>
            <Carousel
              className='product-slider'
              labels={false}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              swipeable={false}
              infiniteLoop
              emulateTouch
              swipeScrollTolerance={0}
              autoPlay
              showArrows={false}
              interval={6000}
            >
              <div
                className='single-item-slide place-image-div'
                style={{ backgroundImage: 'url(/images/places/casino.jpg)' }}
              ></div>
              <div
                className='single-item-slide place-image-div'
                style={{ backgroundImage: 'url(/images/places/hotelroom.jpg)' }}
              ></div>
              <div
                className='single-item-slide place-image-div'
                style={{
                  backgroundImage: 'url(/images/places/operatingroom.jpg)',
                }}
              ></div>
              <div
                className='single-item-slide place-image-div'
                style={{
                  backgroundImage: 'url(/images/places/cafe.jpg)',
                }}
              ></div>
              <div
                className='single-item-slide place-image-div'
                style={{
                  backgroundImage: 'url(/images/places/dentaloffice.jpg)',
                }}
              ></div>
            </Carousel>
          </div>
          <h2>The ImagineAir Series</h2>
        </div>
      </Header>
      {products.map((product, i) => (
        <Link
          to={'/recirculator/' + product.id}
          className={'home-item-flex item' + (i + 1)}
          key={product.id}
        >
          <Item recirculator={product} />
        </Link>
      ))}
    </Container>
  );
}

const Header = styled(Link)`
  color: ${colors.generalBlack};
  grid-column-start: 2;
  grid-column-end: 4;
  order: 2;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(240, 240, 240, 0.2);
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;

  h2 {
    text-align: center;
    font-size: calc(16px + 3vw);
    background-color: rgba(255, 255, 255, 0.3);
    width: fit-content;
    padding: 10px 10px;
    margin: auto;
  }

  .products-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 177, 177, 0.7);
    z-index: -1;
  }

  .products-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(54, 221, 221, 0.7);
    z-index: -1;
  }

  .imaginair-series {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    text-decoration: none;
    color: ${colors.generalWhite};
    background-color: rgba(37, 34, 34, 0.4);

    h2 {
      background-color: rgba(${colors.primaryColor}, 0.5);
    }
  }

  @media (max-width: 1500px) {
    grid-column-start: 1;
    grid-column-end: 1;
    order: 1;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: left;
  text-align: center;
  align-items: center;

  .home-item-flex {
    border-right: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    background-color: #ffffff;
    width: 100%;
    height: 500px;

    :hover {
      .item-display {
        background-color: #f8f8f8;
      }
    }
  }

  .place-image-div {
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
  }

  .place-slide-img {
    margin: 0;
    padding: 0;
  }

  .item1 {
    order: 1;
  }

  .item2 {
    order: 2;
  }
  .item3 {
    order: 3;
  }
  .item4 {
    order: 4;
  }
  .item5 {
    order: 5;
  }
  .item6 {
    order: 6;
  }
  .item7 {
    order: 7;
  }
  .item8 {
    order: 8;
  }
  .item9 {
    order: 9;
  }
  .item10 {
    order: 10;
  }
  .item11 {
    order: 11;
  }

  @media (max-width: 1500px) {
    grid-template-columns: 33.4% 33.4% 33.4%;

    .item1 {
      order: 2;
    }
  }
  @media (max-width: 1260px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 100%;
  }
`;
