import Helmet from 'react-helmet';
import styled from 'styled-components';

import { colors } from '../../components/config';

export default function Certification() {
  const path = '/images/certifications/';
  const certifications = [
    {
      name: 'CE Certification',
      img: 'celogo.png',
    },
    {
      name: 'CSA Certification',
      img: 'csalogo.png',
    },
    {
      name: 'ROHS Certification',
      img: 'rohslogo.png',
    },
    {
      name: 'UL Certification',
      img: 'ullogo.png',
    },
  ];

  return (
    <Container>
      <div
        className={'certification-banner'}
        style={{
          backgroundImage: 'url("/images/places/certificate-presentation.jpg")',
        }}
      ></div>
      <div className='container certifications-display'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Certifications | ImagineAir Systems</title>
          <description>
            At ImagineAir, we strive to provide a complete solution for the
            clean air we breathe. Through the sourcing of various technologies,
            we have concluded that UV-C recirculators provide the safest and
            most effective air sterilization products. We, at ImagineAir, are
            focused on bringing our products to healthcare, hotel/restaurant
            retail, and transportation industries. A complete solution for
            everyone. With ImagineAir systems, you can breathe easy.
          </description>
          <link rel='canonical' href='http://imagineairsystems.com/returns' />
        </Helmet>
        <h2>All ImagineAir recirculators are</h2>
        <div className='certifications'>
          {certifications.map((c) => (
            <div key={c.name}>
              <img src={path + c.img} alt={c.name} />
            </div>
          ))}
        </div>
        <h2>approved.</h2>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .certifications-display {
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      text-align: center;
    }
  }

  .certifications {
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 50px;
    align-items: center;

    div {
      width: 200px;
      height: 200px;
      max-width: 50%;
      max-height: 50vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .certification-banner {
    width: 100%;
    height: 80vw;
    max-height: 100vh;
    background-color: ${colors.primaryColor};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;
