const colors = {
  neutralGrey: '#1c1c1c',
  primaryColor: '#039fdc',
  secondaryColor: '#0126b3',
  generalWhite: '#ffffff',
  generalBlack: '#1d1d1f',
};

const sizes = {
  headerHeight: '70px',
  footerHeight: '260px',
  bodyHeight: 'calc(100vh - 70px - 260px)',
};

export { colors, sizes };
